<template>
  <div>
    <div class="text-center mb-2">
      <el-radio-group v-model="field.is_global_variable" >
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <!-- <el-radio-button :label="true">Global Variable</el-radio-button> -->
      </el-radio-group>
    </div>

    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
    </el-form>
  </div>
</template>

<script>
export default {
  name:"templates-formComponents-SingleLineText",
  components: {
     "TitleAndDescription":()=>import("./TitleAndDescription"),
    // "IsFieldRequired":()=>import("./IsFieldRequired"),
    // "FieldFilledBy":()=>import("./FieldFilledBy"),
    // "Placeholder":()=>import("./Placeholder"),
    // "FieldAttributes":()=>import("./FieldAttributes.vue")
  },
  
  props: ["field","fieldsData"],
  computed: {
  },
  mounted() {
    // this.fetchGlobalVaribales();
  },
  data() {
    return {};
  },
  methods: {
  }
};
</script>

<style lang="scss">
</style>